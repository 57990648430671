export enum NetworkLink {
  Reboot = 'Reboot',
  Network = 'Network',
  DiagnosticResults = 'DiagnosticResults',
}

export enum ShopAddonsLink {
  ShopAddons = 'ShopAddons',
  Checkout = 'Checkout',
}

export enum HelpLink {
  Main = 'Help',
  AgentChat = 'agent-chat',
}

export enum PayBillLink {
  PayBill = 'PayBill',
  UpdateBillCycle = 'UpdateBillCycle',
  OneTimePayment = 'OneTimePayment',
  AutoPay = 'AutoPay',
  Invoice = 'Invoice',
  SelectBillCycle = 'SelectBillCycle',
  ManageBilling = 'ManageBilling',
}

export enum PlanLink {
  Plan = 'PlanUsage',
  ChoosePlanFilter = 'ChoosePlanFilter',
}

export enum StackLink {
  ACP = 'ACP',
  LiveChat = 'LiveChat',
  Profile = 'Profile',
  Stream = 'Stream',
  ScheduleInstallation = 'ScheduleInstallation',
}

export enum TabLink {
  Billing = 'Billing',
}

export enum WebHome {
  ACP = 'ACP',
  OrderSummary = 'OrderSummary',
  Stream = 'Stream',
}

export enum WebRoutes {
  Error = 'Error',
  Home = 'Home',
  Main = '',
}
